




















import Vue from 'vue';
import ConsultingCaseListResourceViewModel from '@/src/services/viewModel/resource/ConsultingCaseListResourceViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    legalEnquiryRequest: new ConsultingCaseListResourceViewModel(),
  }),
  computed: {
    columns() {
      return [
        { value: this.$t('page.legalService.table.subject'), field: 'subject', format: (subject: string) => this.$format.ellipsis(subject, 50) },
        { value: this.$t('page.legalService.table.lastEdited'), field: 'lastEdited', format: (date: string) => this.$format.localDate(date) },
        { value: this.$t('page.legalService.table.status'), field: 'status', format: (status: number) => this.$tEnum('page.legalService.label.status', status) },
      ];
    },
  },
  methods: {
    async populateLegalEnquiryRequestList() {
      const user = await this.$auth.getUser();
      const response = await this.$service.api.consultingCases.getConsultingCasesByUser(user?.id!);
      return response;
    },
  },
});
